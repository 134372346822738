import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _010396e0 = () => interopDefault(import('..\\pages\\error.vue' /* webpackChunkName: "pages_error" */))
const _5cb001ee = () => interopDefault(import('..\\pages\\have-a-card.vue' /* webpackChunkName: "pages_have-a-card" */))
const _49680959 = () => interopDefault(import('..\\pages\\have-a-card\\index.vue' /* webpackChunkName: "pages_have-a-card_index" */))
const _08878158 = () => interopDefault(import('..\\pages\\have-a-card\\eligibility.vue' /* webpackChunkName: "pages_have-a-card_eligibility" */))
const _d427ebe6 = () => interopDefault(import('..\\pages\\have-a-card\\enrollment.vue' /* webpackChunkName: "pages_have-a-card_enrollment" */))
const _2adae8aa = () => interopDefault(import('..\\pages\\have-a-card\\patient-information.vue' /* webpackChunkName: "pages_have-a-card_patient-information" */))
const _32d3204a = () => interopDefault(import('..\\pages\\have-a-card\\success.vue' /* webpackChunkName: "pages_have-a-card_success" */))
const _7a068e11 = () => interopDefault(import('..\\pages\\rejection.vue' /* webpackChunkName: "pages_rejection" */))
const _5e28d9ec = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _96948524 = () => interopDefault(import('..\\pages\\upload-documents\\index.vue' /* webpackChunkName: "pages_upload-documents_index" */))
const _46f5bf31 = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _4703d6b2 = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _472005b4 = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _472e1d35 = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _7bdbfa72 = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _3b7046fc = () => interopDefault(import('..\\pages\\need-a-card\\eligibility.vue' /* webpackChunkName: "pages_need-a-card_eligibility" */))
const _5f1b448f = () => interopDefault(import('..\\pages\\need-a-card\\insurance.vue' /* webpackChunkName: "pages_need-a-card_insurance" */))
const _4459096e = () => interopDefault(import('..\\pages\\need-a-card\\insurance-information.vue' /* webpackChunkName: "pages_need-a-card_insurance-information" */))
const _9b05ca4e = () => interopDefault(import('..\\pages\\need-a-card\\patient-information.vue' /* webpackChunkName: "pages_need-a-card_patient-information" */))
const _15772cc2 = () => interopDefault(import('..\\pages\\need-a-card\\prescriber-information.vue' /* webpackChunkName: "pages_need-a-card_prescriber-information" */))
const _4283facb = () => interopDefault(import('..\\pages\\need-a-card\\select-path.vue' /* webpackChunkName: "pages_need-a-card_select-path" */))
const _48288478 = () => interopDefault(import('..\\pages\\need-a-card\\success.vue' /* webpackChunkName: "pages_need-a-card_success" */))
const _94a286e0 = () => interopDefault(import('..\\pages\\patient\\patientSearch.vue' /* webpackChunkName: "pages_patient_patientSearch" */))
const _888fd34c = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/error",
    component: _010396e0,
    name: "error"
  }, {
    path: "/have-a-card",
    component: _5cb001ee,
    children: [{
      path: "",
      component: _49680959,
      name: "have-a-card"
    }, {
      path: "eligibility",
      component: _08878158,
      name: "have-a-card-eligibility"
    }, {
      path: "enrollment",
      component: _d427ebe6,
      name: "have-a-card-enrollment"
    }, {
      path: "patient-information",
      component: _2adae8aa,
      name: "have-a-card-patient-information"
    }, {
      path: "success",
      component: _32d3204a,
      name: "have-a-card-success"
    }]
  }, {
    path: "/rejection",
    component: _7a068e11,
    name: "rejection"
  }, {
    path: "/unlock",
    component: _5e28d9ec,
    name: "unlock"
  }, {
    path: "/upload-documents",
    component: _96948524,
    name: "upload-documents"
  }, {
    path: "/errors/400",
    component: _46f5bf31,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _4703d6b2,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _472005b4,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _472e1d35,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _7bdbfa72,
    name: "errors-500"
  }, {
    path: "/need-a-card/eligibility",
    component: _3b7046fc,
    name: "need-a-card-eligibility"
  }, {
    path: "/need-a-card/insurance",
    component: _5f1b448f,
    name: "need-a-card-insurance"
  }, {
    path: "/need-a-card/insurance-information",
    component: _4459096e,
    name: "need-a-card-insurance-information"
  }, {
    path: "/need-a-card/patient-information",
    component: _9b05ca4e,
    name: "need-a-card-patient-information"
  }, {
    path: "/need-a-card/prescriber-information",
    component: _15772cc2,
    name: "need-a-card-prescriber-information"
  }, {
    path: "/need-a-card/select-path",
    component: _4283facb,
    name: "need-a-card-select-path"
  }, {
    path: "/need-a-card/success",
    component: _48288478,
    name: "need-a-card-success"
  }, {
    path: "/patient/patientSearch",
    component: _94a286e0,
    name: "patient-patientSearch"
  }, {
    path: "/",
    component: _888fd34c,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
