






















































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import { Resolve } from 'vue-di';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import ISIInfo from '~/components/isi-info/isi-info.vue';
import Footer from '~/components/Footer/Footer.vue';
import IriModule from '~/store/iri';
import FooterModule from '~/store/footer';

@Component<FloatingFooter>({
    components: {
        isiInfo: ISIInfo,
        Footer,
    },
    icons: {
        faChevronUp,
        faChevronDown,
    },
    created() {},

})
export default class FloatingFooter extends Vue {

    //Data
    isIsiExpanded:boolean = false;
    @Resolve
    public iri!: IriModule;

    @Resolve footer!: FooterModule

    public win = {
        h: 0,
        y: 0,
        x: 0,
    };

    public doc = {
        h: 0,
        w: 0,
    };

    public fixedFooter = {
        h: 0,
        w: 0,
    };

    public get staticFooter() {
        return this.footer.staticFooter;
    }

    public get iriOpen() {
        return this.iri.open;
    }

    public set iriOpen(v) {
        this.iri.toggleIriOpen(v);
    }

    public get aboveStaticFooter() {
        const above = this.win.y + this.win.h - this.fixedFooter.h < this.staticFooter.y;
        if (!above) this.isIsiExpanded = true;
        return above;
    }


    public get brkPoints() {
        return {
            'brk-xs': this.$vuetify.breakpoint.xs,
            'brk-xs-only': this.$vuetify.breakpoint.xsOnly,
            'brk-sm': this.$vuetify.breakpoint.sm,
            'brk-sm-only': this.$vuetify.breakpoint.smOnly,
            'brk-sm-and-down': this.$vuetify.breakpoint.smAndDown,
            'brk-sm-and-up': this.$vuetify.breakpoint.smAndUp,
            'brk-md': this.$vuetify.breakpoint.md,
            'brk-md-only': this.$vuetify.breakpoint.mdOnly,
            'brk-md-and-down': this.$vuetify.breakpoint.mdAndDown,
            'brk-md-and-up': this.$vuetify.breakpoint.mdAndUp,
            'brk-lg': this.$vuetify.breakpoint.lg,
            'brk-lg-only': this.$vuetify.breakpoint.lgOnly,
            'brk-lg-and-down': this.$vuetify.breakpoint.lgAndDown,
            'brk-lg-and-up': this.$vuetify.breakpoint.lgAndUp,
            'brk-xl': this.$vuetify.breakpoint.xl,
            'brk-xl-only': this.$vuetify.breakpoint.xlOnly,
        };
    }

    handleClick() {
        this.aboveStaticFooter
            ? this.$vuetify.goTo('#static-footer', {
                duration: 1000,
                easing: 'easeInOutCubic',
                offset: 20,
            })
            : this.$vuetify.goTo('#main-layout', {
                duration: 1000,
                easing: 'easeInOutCubic',
                offset: 0,
            });
    }

    mounted() {
        this.updateWindow();
    }

    public updateWindow(): void {
        this.win = {
            h: window.innerHeight as number,
            x: window.innerHeight as number,
            y: window.pageYOffset as number,
        };
        const fixedFooter = document.querySelector('#fixed-footer') as HTMLElement | null;
        const staticFooter = document.querySelector('#static-footer') as HTMLElement | null;
        const doc = document.body as HTMLElement;
        if (fixedFooter) {
            this.fixedFooter = {
                h: fixedFooter.offsetHeight,
                w: fixedFooter.offsetWidth,
            };
        }
        if (staticFooter) {
            this.footer.setStaticFooter({
                y: staticFooter.offsetTop
            });
        }
        this.doc = {
            h: doc.offsetHeight,
            w: doc.offsetWidth,
        };
    }
}
