export class Settings {
    public constructor(settings: any) {
        Object.assign(this, settings);
    }
}
export interface Settings {
    base: string;
    url(url: string): string;
    name: string;
    author: string;
    description: string;
    minDate: string;
    mainMenu: Array<{ icon: [string, string]; title: string; to: string; exact: boolean }>;
    errorPages: {
        default: { icon: [string, string]; title: string; message: string; statusCode: number };
        [code: string]: { icon: [string, string]; title: string; message: string; statusCode: number };
    };
    card: string;
    headerLogo: string;
    footerLogo: string;
    footerLogoLeft: string;
    footerLogoRight: string;
    programId: string;
    surveyId: string;
    eligibilityFederalQuestion: string;
    eligibilityFederalAnswer: string;
    eligibilityCitizenQuestion: string;
    eligibilityCitizenAnswer: string;
    eligibilityAgeQuestion: string;
    eligibilityAgeAnswer: string;
    eligibilityInsuredQuestion: string;
    eligibilityInsuredAnswer: string;
    triangleUp: string;
    triangleDown: string;
    lockEnabled: string;
    lockPassword: string;
    interestedInTipsQuestion: string;
    interestedInTipsAnswerSchizophrenia: string;
    interestedInTipsAnswerBipolarDisorder: string;
    interestedInTipsAnswerDepression: string;
    interestedInTipsAnswerTouretteSyndrome: string;
    consentToEmailsQuestionId: string;
    consentToEmailsAnswerId: string;
    consentToInformationQuestionId: string;
    consentToInformationAnswerId: string;
    ASIMTUFIIPDF: string;
    prescribingPDF: string;
    adobeScript: string;
}
